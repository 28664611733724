import Button from "./Button";
import { SparklesIcon, PaperAirplaneIcon } from "@heroicons/react/outline";
import SVGGradients from "./SVGGradients";
import { ReactComponent as Logo } from "./assets/codecosmos.svg";

function App() {
  return (
    <div className="App">
      <SVGGradients />

      <header className="h-10 bg-red-200 flex items-center justify-center p-5 py-24 lg:py-36">
        <div className="flex flex-wrap absolute top-5 left-5">
          <div className="flex flex-wrap">
            <Logo className="m-auto pb-3 cursor-pointer text-white h-16 pt-2 pr-2 my-2" />
            <p className="m-auto p-auto w-auto text-white font-medium tracking-wide-2 cursor-pointer lg:text-lg">
              CODEFEVER
            </p>
          </div>
        </div>

        <div className="text-white max-w-6xl">
          <h1 className=" text-3xl md:text-5xl lg:text-6xl uppercase font-weight-600 text-white font-bold mb-10">
            Doe mee aan de
            <span className="gradient-text"> minecraft nerdland </span>
            wedstrijd en win fantastische prijzen!
          </h1>

          <Button
            className="mb-4 md:mb-0 md:mr-4 uppercase inline-block"
            icon={SparklesIcon}
            iconPlacement="right"
            disabled={false}
            isInverted={true}
            href="#start"
          >
            Hoe deelnemen
          </Button>
        </div>

        <div className="absolute bottom-5 right-5 flex items-center">
          <img src="/minecraft.png" alt="" className="w-36 md:w-48 mr-4" />
          <img src="/nerdland.png" alt="" className="w-36 md:w-48" />
        </div>
      </header>

      <div className="px-8 py-10 md:px-20 md:py-10">
        <div className="max-w-6xl m-auto md:flex">
          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm -rotate-2 relative -top-16 -left-8 z-10">
              <img src="/nerdlandaffiche.png" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide leading-6">
              Hé! Verlang jij ook zo naar het
              <span className="gradient-text"> Nerdland Festival?!!</span>
            </h2>

            <p className="mb-2 text-gray-500">
              Wij van{" "}
              <a
                href="https://www.codefever.be/nl"
                className="animated-underline"
              >
                CodeFever VZW
              </a>{" "}
              staan te popelen om te coderen in Scratch en Minecraft en te
              speedcuben met ex-wereldkampioen Mats Valk.
            </p>

            <p className="mb-2 text-gray-500">
              Om het ongeduld wat te verzachten, geven we jullie alvast een
              voorsmaakje!
            </p>

            <p className="mb-2 text-gray-500">
              We bouwden de Nerdland-wereld helemaal na in Minecraft Education
              Edition! Zo kan je alvast het terrein verkennen en ontdekken waar
              je alle tentjes kan terugvinden.
            </p>

            <p className="mb-2 text-gray-500">
              Om het nog leuker te maken, hebben we een aantal zoekvragen voor
              jou geformuleerd! De 30 deelnemers die de meeste antwoorden juist
              hebben én de schiftingsvraag zo goed mogelijk beantwoord hebben,
              winnen één van de prijzen. De prijzen mogen afgehaald worden in de
              Minecraft-stand van CodeFever op het Nerdland-festival.
            </p>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10 bg-gray-100" id="start">
        <div className="max-w-6xl m-auto md:flex">
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide leading-6">
              Klaar om te<span className="gradient-text"> Beginnen? </span>
            </h2>

            <p className="mb-2 text-gray-700 font-semibold">
              Een correcte deelname gebeurt als volgt:
            </p>

            <ul className="text-gray-600 list-disc ml-4">
              <li className="mb-4">
                <p className="mb-4">
                  Klik op de knop “Doe mee” om een account aan te maken op
                  Minecraft:EE en CodeCosmos.
                </p>
                <Button
                  className="mb-4 md:mb-0 md:mr-4 uppercase inline-block"
                  icon={PaperAirplaneIcon}
                  iconPlacement="right"
                  disabled={false}
                  target="_blank"
                  href="https://store.codecosmos.com/api/auth/sign-in?lang=nl&accountType=CODECOSMOS_PARENT&post_login_page=courses&utm_source=nerdland&signup=true"
                >
                  Doe mee
                </Button>
                <br />
                <p className="mt-2 text-sm text-gray-500">
                  Heb je al een account op codecosmos? Zie onderaan.
                </p>
              </li>

              <li className="mb-4">
                Vul je gegevens in. Je zal na het aanmaken van je account een
                mail ontvangen met je Minecraft:EE logingegevens waarbij je 10
                keer gratis kan inloggen.
              </li>

              <li className="mb-4">
                Je bent nu op het tabblad lesmateriaal. Klik verder op
                Nerdlandwedstrijd (onder de titel Demo lesmateriaal).
              </li>

              <li className="mb-4">
                Open de presentatie en kies voor de beginner of expert-route.
                Alle nodige informatie over het downloaden/ aanmelden/ bewegen
                in Minecraft:EE en hoe je de wereld kan downloaden/ importeren
                vind je in deze handleiding terug.
              </li>

              <li className="mb-4">
                Open Minecraft Education Edition en importeer de Nerdlandwereld.
                Meld je aan met de inloggegevens die je hebt ontvangen in je
                mailbox.
              </li>

              <li className="mb-4">
                Open het Microsoft Forms Formulier met de vragen. Zoek de juiste
                antwoorden in de wereld. Duid de antwoorden aan in het Microsoft
                Forms formulier.
              </li>

              <li className="mb-4">
                Een onafhankelijke jury neemt alle inzendingen door. Alle
                prijzen dienen afgehaald te worden op de CodeFever-stand op
                Nerdlandfestival zelf.
              </li>
            </ul>

            <p className="mt-8 mb-16 text-sm text-gray-500">
              <strong>Let op:</strong> Wanneer je al een CodeCosmosaccount had,
              zal je een nieuwe moeten aanmaken zodanig dat ook de
              Nerdlandwedstrijd geopend wordt én je Minecraftlogingegevens
              automatisch worden verzonden naar je mailbox.
            </p>
          </div>

          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm rotate-3 relative -top-16 -right-8">
              <img src="/pc1.jpg" alt="" className="polaroid" />
            </div>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10">
        <div className="max-w-6xl m-auto md:flex">
        <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm -rotate-3 relative -top-16 -left-8 z-10">
              <img src="/minecraftee.webp" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide">
              Programmeren met
              <span className="gradient-text"> Minecraft:EE</span>
            </h2>

            <p className="mb-2 text-gray-500">
              Begint het wat te kriebelen om verder aan de slag te gaan met
              Minecraft Education en wil je ermee leren programmeren?{" "}
            </p>

            <p className="mb-2 text-gray-500">
              Met het CodeCosmos-account dat je hebt aangemaakt in de vorige
              stappen, heb je ook toegang tot een Minecraft Education-coding les
              of Scratch-coding les voor beginners.{" "}
            </p>

            <p className="mb-2 text-gray-500">
              Kijk maar eens onder “Demo Lesmateriaal” wat je allemaal kan
              vinden! Meer informatie over programmeren met CodeCosmos vind je
              hier.
            </p>
          </div>

          
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10 bg-gray-100">
        <div className="max-w-6xl m-auto">
          <h2 className="mb-8 uppercase font-bold text-4xl tracking-wide text-center">
            De <span className="gradient-text">prijzen!</span>
          </h2>

          <div className="max-w-3xl m-auto md:grid md:grid-cols-2 md:gap-8">
            <div className="bg-white rounded-md text-center overflow-hidden mb-8">
              <div className="price1"></div>

              <div className="p-5">
                <div className="text-6xl font-bold mb-4">
                  <span className="gradient-text">1</span>
                  <sup className="text-3xl text-gray-500">ste</sup>
                  <span className="text-3xl text-gray-500">plaats</span>
                </div>
                <p className="text-gray-600">
                  Microsoft Surface Go 3, een Nerdland goodiebag en een
                  CodeCosmos Minecraft-pakket ter waarde van 39 euro waar
                  jongeren van 10 tot 16 jaar mee leren programmeren.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-md text-center overflow-hidden  mb-8">
              <div className="price2"></div>

              <div className="p-5">
                <div className="text-6xl font-bold mb-4">
                  <span className="gradient-text">2</span>
                  <sup className="text-3xl text-gray-500">de</sup>
                  <span className="text-3xl text-gray-500"> plaats</span>
                </div>
                <p className="text-gray-600">
                  5 Minecraft-knuffels en een CodeCosmos Minecraft-pakket ter
                  waarde van 39 euro waar jongeren van 10 tot 16 jaar mee
                  leren programmeren.
                </p>
              </div>
            </div>
          </div>

          <div className="max-w-3xl m-auto">
            <div className="bg-white rounded-md text-center overflow-hidden mb-8">
              <div className="p-5">
                <div className="text-4xl font-bold mb-4">
                  <span className="gradient-text">3-5</span>
                  <sup className="text-2xl text-gray-500">de</sup>
                  <span className="text-2xl text-gray-500"> plaats</span>
                </div>
                <p className="text-gray-600">
                  Een CodeCosmos Minecraft-pakket ter waarde van 39 euro waar
                  jongeren van 10 tot 16 jaar mee leren programmeren.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-md text-center overflow-hidden mb-8">
              <div className="p-5">
                <div className="text-4xl font-bold mb-4">
                  <span className="gradient-text">5-30</span>
                  <sup className="text-2xl text-gray-500">ste</sup>
                  <span className="text-2xl text-gray-500"> plaats</span>
                </div>
                <p className="text-gray-600">Een CodeFever-drinkfles.</p>
              </div>
            </div>

            <p className="text-gray-600 text-center">
              <strong>Belangrijk:</strong> De prijzen moeten worden opgehaald
              aan onze stand op het Nerdland-Festival.
            </p>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10 text-gray-600 ">
        <div className="max-w-6xl m-auto md:flex">
        <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm rotate-3 relative top-16 -left-8 z-10">
              <img src="/codefever.jpeg" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-xl tracking-wide leading-6">
              <span className="gradient-text">CodeFever</span> in een notendop
            </h2>

            <ul className="list-disc ml-4">
              <li className="mb-4">
                Een educatief onderbouwde lesmethode met uniek lespakket
              </li>

              <li className="mb-4">Verschillende instapgroepen op leeftijd </li>

              <li className="mb-4">
                Een doordacht vervolgtraject op semesterbasis (Scratch,
                Minecraft Education, JavaScript, Python, ...)
              </li>

              <li className="mb-4">
                Toegang tot het online CodeFever-leerplatform
              </li>

              <li className="mb-4">
                Professionele lesgevers uit de IT-wereld
              </li>

              <li className="mb-4">
                Een groepsgebeuren: je kind leert met andere kinderen eigen
                spelletjes programmeren
              </li>

              <li className="mb-4">
                Begeleide lessen in kleine groepen met hoge funfactor
              </li>
            </ul>
            <p className="gradient-text mb-4 font-bold">
               <span className="gradient-text mb-4 font-bold">&lt;HEY HO, LET’S GO!&gt;</span>
            </p>

            <p className="mb-4">
              Je kan jouw cybermissie telkens starten in september en/of
              februari tijdens onze fysieke lesreeksen of op één van onze
              themakampen. Wil je liever eerst een verkenningsmissie doen? Test
              het uit met een gratis online proefles op onze website!
            </p>

            <p>
              Wil je meer informatie over de digitale leerpaden om te leren
              programmeren in Scratch en in Minecraft Education Edition voor
              thuis, surf dan naar{" "}
              <a
                className="animated-underline"
                href="https://www.codecosmos.com/"
              >
                www.codecosmos.be
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 p-8 text-center text-gray-300 text-sm">
      © CodeFever 2022 -  <a target="_blank" href="/wedstrijdreglement.pdf" className="animated-underline">Wedstrijdreglement</a>
      </div>
    </div>
  );
}

export default App;
